import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Card from '@material-ui/core/Card';
import CardHeader from '@material-ui/core/CardHeader';
import CardMedia from '@material-ui/core/CardMedia';
import CardContent from '@material-ui/core/CardContent';
import Avatar from '@material-ui/core/Avatar';
import Typography from '@material-ui/core/Typography';
import { red } from '@material-ui/core/colors';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import ArrowRightIcon from '@material-ui/icons/ArrowRight';
import DetailedProject from './DetailedProject';
import { StylesProvider } from "@material-ui/core/styles";
import "./styles.css";

const useStyles = makeStyles((theme) => ({
  root: {
    maxWidth: 345,
  },
  media: {
    height: 0,
    paddingTop: '56.25%', // 16:9
  },
  expand: {
    transform: 'rotate(0deg)',
    marginLeft: 'auto',
    transition: theme.transitions.create('transform', {
      duration: theme.transitions.duration.shortest,
    }),
  },
  expandOpen: {
    transform: 'rotate(180deg)',
  },
  avatar: {
    backgroundColor: red[500],
  },
}));

const PortfolioCard = props => {
  const classes = useStyles();

  return (
    <Card className={classes.root}>
      <CardHeader
        avatar={
          <Avatar aria-label="recipe" className={classes.avatar}>
            <img style={{width:40, height:40}} src={props.logo} alt="Company logo" />
          </Avatar>
        }
        title={<strong>{props.title}</strong>}
        subheader={props.subheader}
      />
      <CardMedia
        className={classes.media}
        image={props.media}
      />
      <CardContent> 
        {
          typeof props.contents === "string" ? 
          <Typography color="textSecondary" paragraph>{props.contents}</Typography> :
          props.contents.map( (content, index) => 
          <StylesProvider injectFirst>
          <List key={index}>
            <ListItem>
              <ListItemIcon>
                <ArrowRightIcon />
              </ListItemIcon>
              <ListItemText secondary={content} />
            </ListItem>
          </List>
          </StylesProvider>
          )
        }
      </CardContent>
      {
        props.detailedContents ? 
        <DetailedProject project={props.detailedContents}/>
        : null
      }
      
    </Card>
  );
}

export default PortfolioCard;