import email from "../../src/assets/social/gmail_icon.png";
import facebook from "../../src/assets/social/facebook_icon.png";
import linkedin from "../../src/assets/social/linkedin_icon.png";
import whatsapp from "../../src/assets/social/whatsapp_icon.png";

const SOCIAL_PROFILES = [
    {
        id: 1,
        title: "gmail",
        link: "mailto:halleysalamcha@gmail.com",
        icon: email
    },
    {
        id: 2,
        title: "facebook",
        link: "https://www.facebook.com/halleysalamcha",
        icon: facebook
    },
    {
        id: 3,
        title: "linkedin",
        link: "https://www.linkedin.com/in/halley-salam-1b123762/",
        icon: linkedin
    },
    {
        id: 4,
        title: "whatsapp",
        link: "https://wa.me/918792207067",
        icon: whatsapp
    }
];

export default SOCIAL_PROFILES;