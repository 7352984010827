import React, { Component } from "react";
import PROJECTS from "./data/project";
import DetailedProject from "./DetailedProject";
import PortfolioCard from "./PortfolioCard";

class Project extends Component {
    render() {
        const { title, summary } = this.props.project;
        return (
            <div className="project">
                <h3>{title}</h3>
                <hr />
                <h4>Summary</h4>
                <div><em>{summary}</em></div>
                <DetailedProject project={this.props.project}/>
            </div>
        )
    }
}

class Projects extends Component {
    render() {
        return (
            <div>
                <h2>Professional Projects</h2>
                <div className="section-wrapper">
                    {
                        PROJECTS.map(PROJECT => {
                            return (
                                <div key={PROJECT.id} style={{ margin: 10 }}>        
                                    <PortfolioCard 
                                        logo={PROJECT.logo}
                                        title={PROJECT.title} 
                                        subheader={PROJECT.association} 
                                        media={PROJECT.image} 
                                        contents={PROJECT.summary} 
                                        detailedContents={PROJECT}
                                        />
                                 </div>
                            );
                        })
                    }
                </div>
            </div>
        )
    }
}

export default Projects;