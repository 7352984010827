import React, { Component } from "react";
import WORK_HISTORY from "./data/workHistory"; 
import PortfolioCard from "./PortfolioCard";

class Work extends Component {
    render() {
        const { title, responsibilities } = this.props.work;
        return(
            <div className="bio-data">
            <h3>{title}</h3>
            <hr />
            {
                responsibilities.map( (responsibility, index) => <li key={index}><em>{responsibility}</em></li>)
            }
        </div>
        )
    }
}

class WorkHistory extends Component {
    render() {
        return (
            <div>
                <h2>Work Histories</h2>
                <div className="section-wrapper">
                    {
                        WORK_HISTORY.map(WORK => {
                            return (
                                <div key={WORK.id} style={{ margin: 10 }}>        
                                    <PortfolioCard 
                                        logo={WORK.logo}
                                        title={WORK.title} 
                                        subheader={WORK.tenure} 
                                        media={WORK.image} 
                                        contents={WORK.responsibilities} />
                                 </div>
                                
                            );
                        }) 
                    }
                </div>
            </div>
        );
    }
}

export default WorkHistory;