
import React, { Component } from "react";
import profile from "./assets/profile.jpg";
import SUMMARY  from "./data/summary";
import { EXPERIENCE_DETAILS } from "./data/bioData";
import Bios from "./Bio";
import WorkHistory from "./WorkHistory";
import Projects from "./Project";
import SocialProfiles from "./SocialProfiles";
import Button from '@material-ui/core/Button';
import { calculateServicePeriod } from "./helper";

class App extends Component {
  constructor() {
    super();
    this.state = { showBio: false };
    this.toggleShowBio = this.toggleShowBio.bind(this);
  }

  toggleShowBio() {
    this.setState({ showBio: !this.state.showBio });
  }

  render() {
    let toggleShowBioLabel = !this.state.showBio ? "Show Bio" : "Hide Bio";

    return (
      <div>
        <h1>{SUMMARY.greet}</h1>
        <img className="profile" src={profile} alt="Profile Pic" /> 
        <p>{SUMMARY.title}</p>
        <p><strong><em>Summary</em></strong></p>
        {/*<p><em>{SUMMARY.subTitle}</em></p>*/}
        {
          EXPERIENCE_DETAILS.map(details =>  <p><em>{details.replace("yearOfExperience", calculateServicePeriod())}</em></p>)
        }
        {
          this.state.showBio ? <Bios /> : null
        }
        <div>
          <Button variant="contained" color="primary" onClick={this.toggleShowBio}>
            {toggleShowBioLabel}
          </Button>
        </div>
        <hr />
        <WorkHistory />
        <hr />
        <Projects />
        <hr />
        <SocialProfiles />
      </div>
    )
  }
}

export default App;
