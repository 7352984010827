export const calculateServicePeriod = () => {
    var joiningDate = new Date(2011, 9, 24);
    //extract the year, month
    var startYear = joiningDate.getYear();  
    var startMonth = joiningDate.getMonth(); 
    //get the current date from the system
    var now = new Date();  
    //extract the year, month
    var currentYear = now.getYear();  
    var currentMonth = now.getMonth(); 
    var noOfYear = currentYear - startYear;
    var noOfMonth = null;
    //get months
    if (currentMonth >= startMonth )  
      //get months when current month is greater
      noOfMonth = currentMonth - startMonth ;  
    else {
      noOfYear--;  
      noOfMonth = 12 + currentMonth - startMonth ;  
    }
    return noOfYear + ((noOfMonth < 1) ?  " years" : " years and " + noOfMonth + " months");
  }
