import education_logo from "../../src/assets/bio/education_logo.jfif";
import education from "../../src/assets/bio/education.jfif";
import techSkill_logo from "../../src/assets/bio/teckSkills.jfif";
import techSkill from "../../src/assets/bio/technicalSkills.jfif";
import personalInfo_logo from "../../src/assets/bio/personalInformation.png";
import personalInfo from "../../src/assets/bio/personalInfo1.png";


const BIO_DATA = [
    {
        id: 1,
        title: "Education",
        logo: education_logo,
        description: [
            "B.E at SSGB COET, Bhusaval, Maharastra with 63.4%  (2010).",
            "10+2 at Human Resource Development Academy, Manipur with 74.8% (2005).",
            "10 at Sofia High School, Manipur with 71.5% (2003)."
        ],
        image: education
    },
    {
        id: 2,
        title: "Technical Skills",
        logo: techSkill_logo,
        description: [
            "Frontend Technologies - HTML, JavaScript, OOJS, ES6, CSS, Angular, Mithril.js, React.js, Node.js",
            "Testing Framework - Jasmine Unit Testing",
            "Practical skills in Responsive Web design and Mobile Web development",
            "DevOps - Jenkins, Docker, Kubernetes",
            "Backend Technologies - Java Spring Boot Framework, RabbitMQ Messaging, Swagger API"
        ],
        image: techSkill
    },
    {
        id: 3,
        title: "Personal Information",
        logo: personalInfo_logo,
        description: [
            "DOB - 05/03/1988",
            "Nationality: Indian",
            "Marital Status: Married",
            "Hobbies: Cricket, Music and Coding"
        ],
        image: personalInfo
    }
];

export const CORE_RESPONSIBILITIES = [
    "Contribution to the Application Architecture Design",
    "Work closely with designers and clients to implement front end solutions",
    "Actively participate in design and code reviews to build robust applications and prototypes",
    "Taking ownership and initiatives in building innovative products",
    "Mentoring new employee about the work environment including tools used and product workflow",
    "Actively working in Safe Agile development environment"
];

export const EXPERIENCE_DETAILS = [
    "I have yearOfExperience of experience in IT industry working extensively in Front End applications including - Design Architecture, Development and Unit Testing.",
    "I also have experience working in DevOps Tools and Cloud Technologies such as Dockers and Kubernetes."
];

export default BIO_DATA;