import amadeusLogo from "../../src/assets/company/amadeus1.png";
import motorolaLogo from "../../src/assets/company/motorola1.png";
import cernerLogo from "../../src/assets/company/cerner2.png";
import icthealthLogo from "../../src/assets/company/ictHealth2.jfif";

import controlTower from "../../src/assets/project/Control_tower.png";
import DM from "../../src/assets/project/DM.png";
import mPages from "../../src/assets/project/mPages.jfif";
import pushToTalk from "../../src/assets/project/ptt.jfif";
import IHN from "../../src/assets/project/IHN.jfif";
import HN from "../../src/assets/project/HN.jfif";

import bitbucket from "../../src/assets/tools/bitbucket-tool.png";
import androidStudio from "../../src/assets/tools/android-studio-tool.jpg";
import crucible from "../../src/assets/tools/crucible-tool.jpg";
import docker from "../../src/assets/tools/docker-tool.png";
import eclipse from "../../src/assets/tools/eclipse-tool.jpg";
import github from "../../src/assets/tools/github-tool.png";
import jenkins from "../../src/assets/tools/jenkins-tool.png";
import jFrog from "../../src/assets/tools/jFrog-tool.png";
import jira from "../../src/assets/tools/jira-tool.jfif";
import maven from "../../src/assets/tools/maven-tool.png";
import prometheus from "../../src/assets/tools/prometheus-tool.jpg";
import rabbitmq from "../../src/assets/tools/rabbitmq-tool.jpg";
import sonarQube from "../../src/assets/tools/sonarqube-tool.png";
import svn from "../../src/assets/tools/svn-tool.png";
import tomcat from "../../src/assets/tools/tomcat-tool.jfif";

import angular from "../../src/assets/tech/angular.png";
import angular_material from "../../src/assets/tech/angular_material.png";
import TS from "../../src/assets/tech/TS.png";
import angularjs from "../../src/assets/tech/angularjs.png";
import ansible from "../../src/assets/tech/ansible.png";
import bootstrap from "../../src/assets/tech/bootstrap.png";
import cassandra from "../../src/assets/tech/cassandra.jpeg";
import couchDB from "../../src/assets/tech/couchDB.png";
import css3 from "../../src/assets/tech/css3.png";
import ccl from "../../src/assets/tech/ccl.jpg";
import extjs from "../../src/assets/tech/extjs.jpg";
import html5 from "../../src/assets/tech/html5.png";
import JS from "../../src/assets/tech/JS.png";
import ldap from "../../src/assets/tech/ldap.jpg";
import mariaDB from "../../src/assets/tech/mariaDB.png";
import mithril from "../../src/assets/tech/mithril.jpeg";
import mongoDB from "../../src/assets/tech/mongoDB.png";
import mySql from "../../src/assets/tech/mySql.png";
import node from "../../src/assets/tech/node.jpg";
import Oauth2 from "../../src/assets/tech/oauth2-1.png";
import oracle from "../../src/assets/tech/oracle.png";
import redisDB from "../../src/assets/tech/redisDB.png";
import springboot from "../../src/assets/tech/springboot.jpg";

const PROJECTS = [
    {
        id: 1,
        association: "Amadeus Software Labs",
        title: "Control Tower",
        logo: amadeusLogo,
        summary: "Control Tower is a CI/CD pipeline platform to activate Software Workbench for the project.",
        description: [
            "Is a CI/CD pipeline platform where user can activate Software Workbench for his/her project in a self-service manner.",
            "It also facilitates User to monitor individual Software Workbench Component such as BitBucket, Artifactory and Jenkins \
            pipeline. Each component can be updated at any point of time without impacting the platform."
        ],
        technologies: [
            [html5, css3, JS, angular, angular_material, TS],
            [springboot],
            [mariaDB, redisDB],
            [ldap],
            [ansible],
            [rabbitmq]
           /* "Angular as Frontend application and Spring Boot framework as Backend",
            "MySQL and LDap as database",
            "Ansible",
            "RabbitMQ for messaging queue",
            "UPM for Single Sign On"*/
        ],
        tools: [
            bitbucket,
            jenkins,
            jFrog,
            sonarQube,
            prometheus,
            docker
        ],
        responsibilities: [
            "Development in Frontend and Backend",
            "Code Quality and Performance improvement",
            "Creating Kubernetes Manifest files and deploying in Azure cloud"
        ],
        image: controlTower
    },
    {
        id: 2,
        association: "Amadeus Software Labs",
        title: "Deployment Manager",
        logo: amadeusLogo,
        summary: "Deployment Manager is a tool for unifying the deplyements changes in Amadeus.",
        description: [
            "Is in charge of unifying the deployment of changes in Amadeus.",
            "It ensures that a set of required steps are verified before allowing a change to be loaded on an environment \
            so that applications can be deployed in a safer way."
        ],
        technologies: [
            [html5, css3, JS, angular, angular_material, TS],
            [springboot],
            [mariaDB],
            [rabbitmq],
            [Oauth2]
           /* "Angular as Frontend application and Spring Boot framework as Backend",
            "MySQL as database",
            "RabbitMQ for messaging queue",
            "OAuth 2 for Single Sign On"*/
        ],
        tools: [
            bitbucket,
            jenkins,
            sonarQube,
            docker,
            rabbitmq
        ],
        responsibilities: [
            "Development in Frontend and Backend"
        ],
        image: DM
    },
    {
        id: 3,
        association: "Motorola Solutions",
        title: "Push To Talk App",
        logo: motorolaLogo,
        summary: "Push to Talk App is a tool that provides mode of communication in wireless cellular phones.",
        description: [
            "PTT is a means of instantaneous communication commonly employed in wireless cellular phone services that uses \
            a button to switch a device from voice transmission mode to voice reception mode.",
            "Advantage of PTT is the ability for a sinle person to reach an active talk group with a single button press; \
            users need not make several telephone calls to coordinate with a group.",
            "Push-to-talk cellular calls similarly provide half duplex communications - while one person transmits, the \
            other(s) receive. This combines the operational advantages of PTT with the interference resistance and other \
            virtues of mobile phones."
        ],
        technologies: [
            [html5, css3, JS, extjs],
            [springboot],
            [oracle]
            /*"Frontend - HTML, CSS, JavaScript, EXTJS",
            "Java for Backend",
            "Oracle as database"*/
        ],
        tools: [
            androidStudio,
            svn,
            jenkins,
            jira,
            crucible
        ],
        responsibilities: [
            "UI Development"
        ],
        image: pushToTalk
    },
    {
        id: 4,
        association: "Cerner Healthcare Solutions Pvt. Limited",
        title: "MPages",
        logo: cernerLogo,
        summary: "MPages is a Web-based platform that enables clients to view Cerner Millenium data.",
        description: [
            "MPages is a Web-based platform that enables clients to create customized views of Cerner Millennium data at the \
            organizer or chart level from within Cerner's solution such as Powerchart, FirstNet, and SurgiNet.",
            "MPages enables an ad hoc query to Cerner's decision support system, allowing for proactive and interactive \
            monitoring of real-time patient scenarios crossing many different roles, venues and conditions within an organization.",
            "Enables organization to improve clinical workflows, increase user satisfaction and improve patient care."
        ],
        technologies: [
            [html5, css3, JS, mithril, node],
            [ccl],
            [oracle]
           /* "Frontend - HTML, CSS, JavaScript, Mithril.js, Node.js, Jasmine Unit Testing",
            "Cerner Command Language for Banckend",
            "Oracle as database"*/
        ],
        tools: [
            eclipse,
            github,
            maven,
            jenkins,
            jira
        ],
        responsibilities: [
            "UI Development and Testing",
            "Creating Driver script for constructing base HTML",
            "Writing backend scripts in Cerner COmmand Language"
        ],
        image: mPages
    },
    {
        id: 5,
        association: "ICT Health",
        title: "Integrated Health Networks - Web App",
        logo: icthealthLogo,
        summary: "Integrated Health Networks is a unified strategic healtcare informatics platform for hospitals.",
        description: [
            "IHN is a strategic healthcare informatics platform for hospitals, integrated delivery networks (IDNs).",
            "This is a unified platform with a range of capabilities for active analytics, accessing structured and \
            unstructured data, analysis of free text, information exchange and another valuable healthcare informatics."
        ],
        technologies: [
            [html5, css3, JS],
            [springboot],
            [cassandra, mongoDB]
            /*"Frontend - HTML5, CSS, JavaScript, jQuery and Ajax",
            "J2EE and Spring MVC",
            "Cassandra and Mongo DB"*/
        ],
        tools: [
            eclipse,
            svn,
            maven,
            tomcat,
            jira
        ],
        responsibilities: [
            "Designing Login Screen and Registration Screen",
            "Designing Client Report using SVG",
            "Creating Charts for displaying Statistical Reports"
        ],
        image: IHN
    },
    {
        id: 6,
        association: "ICT Health",
        title: "Hinai Network",
        logo: icthealthLogo,
        summary: "Hinai Network is Health Information Network comprises Patient and Physician Portals.",
        description: [
            "Hinai Network is health information System Network. It comprises of Patient and Physician portals.",
            "Through HN, the User can viewhis upcoming appointments, alerts and notifications, and the latest news updates \
            from the HN. ",
            "The User can also view Service Directory which is a list of facilities/sites the user can avail services from."
        ],
        technologies: [
            [html5, css3, JS, angularjs, bootstrap, node],
            [springboot],
            [mySql, couchDB]
            /*"Frontend - HTML5, CSS, JavaScript, jQuery and Ajax, AngularJS, Bootstrap, Node.js",
            "J2EE and Spring MVC",
            "MySQL and Couch DB"*/
        ],
        tools: [
            eclipse,
            svn,
            maven,
            tomcat,
            jira
        ],
        responsibilities: [
            "Creating HTML Pages",
            "Creating AngularJS directives",
            "Creating handlers in node.js to handle request from client side"
        ],
        image: HN
    }
];

export default PROJECTS;