import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import ListItemText from '@material-ui/core/ListItemText';
import ListItem from '@material-ui/core/ListItem';
import List from '@material-ui/core/List';
import Divider from '@material-ui/core/Divider';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import IconButton from '@material-ui/core/IconButton';
import Typography from '@material-ui/core/Typography';
import CloseIcon from '@material-ui/icons/Close';
import Slide from '@material-ui/core/Slide';
import Collapse from '@material-ui/core/Collapse';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ArrowRightIcon from '@material-ui/icons/ArrowRight';

const useStyles = makeStyles((theme) => ({
  appBar: {
    position: 'relative',
  },
  title: {
    marginLeft: theme.spacing(2),
    flex: 1,
  },
}));

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

const ProjectDetails = props => {
    const [open] = React.useState(true);
    const MediaDetails = props => {
      return (
        <div>
        {
          props.contentType === "media" ?
          <img src={props.details} alt="" style={{width: 200, height: 60}} />
          :  props.details.map( detail => <img src={detail} alt="" style={{width: 80, height: 50, margin: 5}} />) 
        }
        </div>
        
        
      )
    }
    return (
      <div style={{margin: 20}}>
        <ListItem alignItems="center">
          <ListItemText primary={<strong>{props.sectionName}</strong>} />
        </ListItem>
        <Collapse in={open} timeout="auto" unmountOnExit>
          <List component="div" disablePadding>
              {
                props.details.map( (detail, index) => {
                  return(
                    <ListItem>
                      <ListItemIcon>
                        <ArrowRightIcon />
                      </ListItemIcon>
                      {
                        props.contentType === "text" ? 
                          <ListItemText primary={detail} />
                          : <MediaDetails contentType={props.contentType} details={detail} />
                      }
                    </ListItem>
                  )
                })
              }
          </List>
        </Collapse>
      </div>
    )
}

const DetailedProject = props =>  {
  const { title, description, technologies, tools, responsibilities } = props.project;
  const classes = useStyles();
  const [open, setOpen] = React.useState(false);

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  return (
    <div>
      <Button color="primary" onClick={handleClickOpen}>
        Read More...
      </Button>
      <Dialog fullScreen open={open} onClose={handleClose} TransitionComponent={Transition}>
        <AppBar className={classes.appBar}>
          <Toolbar>
            <IconButton edge="start" color="inherit" onClick={handleClose} aria-label="close">
              <CloseIcon />
            </IconButton>
            <Typography variant="h6" className={classes.title}>
              {title}
            </Typography>
          </Toolbar>
        </AppBar>
        <div className="detailed-project-content">
        <List>
          <ProjectDetails sectionName="Description:" contentType="text" details={description} />
          <Divider />
          <ProjectDetails sectionName="Technologies:" contentType="media-array"  details={technologies} />
          <Divider />
          <ProjectDetails sectionName="Tools:"  contentType="media" details={tools} />
          <Divider />
          <ProjectDetails sectionName="Roles and Responsibilities:"  contentType="text" details={responsibilities} />
        </List>
        </div>
        
      </Dialog>
    </div>
  );
}

export default DetailedProject;
