import React,{ Component } from "react";
import BIO_DATA, { CORE_RESPONSIBILITIES, EXPERIENCE_DETAILS } from "./data/bioData";
import { calculateServicePeriod } from "./helper";
import PortfolioCard from "./PortfolioCard";

class Bio extends Component {
    render() {
        const { title, description } = this.props.bioData;
        return (
            <div className="bio-data">
                <h3>{title}</h3>
                <hr />
                {
                    description.map( (desc, index) => <li key={index}><em>{desc}</em></li>)
                }
            </div>
        );
    }
}

class Bios extends Component {
    render() {
        return (
            <div>
                <hr />
                <div>
                    {/*<h2>Experience</h2>
                    {
                        EXPERIENCE_DETAILS.map(details =>  <p><em>{details.replace("yearOfExperience", calculateServicePeriod())}</em></p>)
                    }
                    */}
                    <h2>Core Responsibilities</h2>
                    {
                        CORE_RESPONSIBILITIES.map( (responsibility, index) => <li style={{margin:10}} key={index}><em>{responsibility}</em></li>)
                    }
                    <br />
                </div>
                <div className="section-wrapper">
                    {
                        BIO_DATA.map(DATA => {
                            return (
                                <div key={DATA.id} style={{ margin: 10 }}>  
                                    <PortfolioCard 
                                            logo={DATA.logo}
                                            title={DATA.title}
                                            media={DATA.image} 
                                            contents={DATA.description} />
                                </div>
                            );
                        })
                    }
                </div>
            </div>
        );
    }  
}

export default Bios;