import React, { Component } from "react";
import SOCIAL_PROFILES from "./data/socialProfiles";

class SocialProfile extends Component {
    render() {
        const { title, link, icon } = this.props.socialProfile;
        return(
        <span>
            <a href={link}><img className="social-profile-icon" src={icon} alt={title} /></a>
        </span>
        )  
    }
}

class SocialProfiles extends Component {
    render() {
        return (
            <div>
                <h2>Connect me @:</h2>
                <div>
                    {
                        SOCIAL_PROFILES.map( PROFILE => {
                            return (
                                <SocialProfile key={PROFILE.id} socialProfile={PROFILE} />
                            );
                        }) 
                    }
                </div>
            </div>
        );
    }
}

export default SocialProfiles;