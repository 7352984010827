import amadeusLogo from "../../src/assets/company/amadeus1.png";
import motorolaLogo from "../../src/assets/company/motorola1.png";
import cernerLogo from "../../src/assets/company/cerner2.png";
import icthealthLogo from "../../src/assets/company/ictHealth2.jfif";

import amadeus from "../../src/assets/company/amadeus3.png";
import motorola from "../../src/assets/company/motorola4.png";
import cerner from "../../src/assets/company/cerner1.png";
import icthealth from "../../src/assets/company/ictHealth.jpg";

const WORK_HISTORY = [
    {
        id: 1,
        title: "Member Technical Staff",
        association: "Amadeus Software Labs",
        tenure: "Jan 2020 till now",
        logo: amadeusLogo,
        responsibilities: [
            "Frontend and Backend Code Development, Code Quality and Performance Improvement",
            "Worked on Containerization",
            "Preparing Kubernetes Objects and deployment in Azure Cloud"
        ],
        image: amadeus
    },
    {
        id: 2,
        title: "Software Enginer 2",
        association: "Motorola Solutions",
        tenure: "May 2019 to Jan 2020",
        logo: motorolaLogo,
        responsibilities: [
            "Responsible in Design Approach",
            "Development"
        ],
        image: motorola
    },
    {
        id: 3,
        title: "Senior Softwar Engineer",
        association: "Cerner Healthcare Solutions Pvt. Limited",
        tenure: "August 2013 to May 2019",
        logo: cernerLogo,
        responsibilities: [
            "Responsible for quality design and development",
            "Frontend Development with HTML, JavaScript, CSS, Mithril.js",
            "Backend Development with CCL (Cerner Command Language)",
        ],
        image: cerner
    },
    {
        id: 4,
        title: "Software Engineer",
        association: "ICT Health",
        tenure: "Sept 2011 to July 2013",
        logo: icthealthLogo,
        responsibilities: [
            "Development and Testing",
            "Support Activities"
        ],
        image: icthealth
    }
];

export default WORK_HISTORY;